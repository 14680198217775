export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Bolsominions.org",

  author: "Júnior Boceli",
  description: "Blog que reune material de divulgação das realizações do presidente Jair Bolsonaro.",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}