import React from 'react'
import styles from './Bio.module.css'
import { getGravatarURL } from '../utils/getGravatarURL'

function Bio(props) {
  let photoURL = getGravatarURL({
    email: "juniorbocelli@gmail.com",
    size: 56,
  })

  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={photoURL} alt="Mito" />
      <p>
        Blog criado para mostrar aos brasileiros aquilo que a mídia não mostra{' '}
        <br />
        O foco ao criarmos este blog foi facilitar o compartilhamento de{' '}
        conteúdos em qualquer rede social.{' '}
        <br />
        Esperamos que todos aproveitem e colaborem!{' '}
      </p>
    </div>
  )
}

export default Bio
