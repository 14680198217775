import React from 'react';
import {
  EmailShareButton,
  EmailIcon,

  FacebookShareButton,
  FacebookIcon,

  FacebookMessengerShareButton,
  FacebookMessengerIcon,

  TelegramShareButton,
  TelegramIcon,

  TwitterShareButton,
  TwitterIcon,

  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import styles from './Share.module.css'

function Share({ blogRoot, meta, title }) {
  const iconSize = 32;
  const currentUrl = window.location.href;

  return (
    <aside className={styles.Share}>
      <div className={styles.SocialMediaIconContainer}>
        <FacebookShareButton
          url={currentUrl}
          quote={title}
        >
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>
      </div>

      <div className={styles.SocialMediaIconContainer}>
        <FacebookMessengerShareButton
          url={currentUrl}
          appId="937320603839292"
        >
          <FacebookMessengerIcon size={iconSize} round />
        </FacebookMessengerShareButton>
      </div>

      <div className={styles.SocialMediaIconContainer}>
        <WhatsappShareButton
          url={currentUrl}
          title={title}
        >
          <WhatsappIcon size={iconSize} round />
        </WhatsappShareButton>
      </div>

      <div className={styles.SocialMediaIconContainer}>
        <TwitterShareButton
          url={currentUrl}
          title={title}
        >
          <TwitterIcon size={iconSize} round />
        </TwitterShareButton>
      </div>

      <div className={styles.SocialMediaIconContainer}>
        <TelegramShareButton
          url={currentUrl}
          title={title}
        >
          <TelegramIcon size={iconSize} round />
        </TelegramShareButton>
      </div>

      <div className={styles.SocialMediaIconContainer}>
        <EmailShareButton
          url={currentUrl}
          subject={title}
          body={meta.spoiler}
        >
          <EmailIcon size={iconSize} round />
        </EmailShareButton>
      </div>
    </aside>
  );
};

export default Share;